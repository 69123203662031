import React, { Component } from 'react'
import Link from '../utils/link'

import NewsletterForm from '../components/newsletter-popup'

class Footer extends Component {
  render() {

    // let { twitter } = this.props.data.wp

    //let { footer } = this.props.data.wp.siteOptions.acf

    return (
      <footer className='footer'>

        {/* {console.log(footer)} */}

        <div className='footer__inner'>
          <div className='footer__item'>
            <NewsletterForm/>
          </div>

          <div className='footer__item'>
            <nav className='footer__nav'>
              <ul>
                <li><Link to='/privacy-policy'>PRIVACY POLICY</Link></li>
                <li><Link to='https://twitter.com/elder_london'>TWITTER</Link></li>
                <li><Link to="https://www.instagram.com/elder__london">Instagram</Link></li>
                <li><p>Elder {new Date().getFullYear()}&#8482;</p></li>
              </ul>
            </nav>
          </div>

        </div>
      </footer>
    )
  }
}

export default Footer
