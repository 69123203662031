import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="77" height="20" viewBox="0 0 77 20" fill="none">
        <g>
          <path d="M63.8867 2.6275H66.7287C68.7663 2.6275 70.1868 3.51233 70.1868 5.62983C70.1868 7.74733 68.8468 8.71317 66.6483 8.71317H63.8868V2.6275H63.8867ZM63.8867 11.3405H66.9163L72.4925 20H76.1657L69.8652 10.6167V10.4827C71.7955 9.67833 73.297 7.90883 73.297 5.469C73.297 2.30583 70.884 0 67.2918 0H60.8303V20H63.8868V11.3405H63.8867ZM49.9727 17.292V11.1528H56.4872V8.47167H49.9727V2.70783H57.1307V0H46.9162V20H57.2378V17.2922H49.9725L49.9727 17.292ZM29.4102 17.292V2.70783H32.7342C37.587 2.70783 40.911 4.98633 40.911 10C40.911 15.0137 37.6673 17.2922 32.7342 17.2922H29.4102V17.292ZM33.2973 19.9998C39.8923 19.9998 44.048 15.9513 44.048 9.91933C44.048 3.88733 39.8118 0 33.5922 0H26.3537V20H33.2972L33.2973 19.9998ZM16.9705 17.292V0H13.9145V20H23.646V17.2922H16.9705V17.292ZM3.0565 17.292V11.1528H9.571V8.47167H3.0565V2.70783H10.2145V0H0V20H10.3217V17.2922H3.0565V17.292Z" fill={color} />
        </g>
      </svg>
    )
  }
}

class Close extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.353553" y1="0.649376" x2="20.3536" y2="20.6494" stroke={color}/>
        <line x1="20.3536" y1="1.35648" x2="0.353554" y2="21.3565" stroke={color}/>
      </svg>
    )
  }
}

class DownArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.26959 0.120075L9.26959 14.522L2.09316 7.34561L0.439941 8.99883L8.78555 17.3444V17.3468L10.4388 19H10.4411L12.0943 17.3468V17.3444L20.4399 8.99883L18.7867 7.34561L11.608 14.522L11.608 0.120075H9.26959Z" fill={color}/>
      </svg>
    )
  }
}

class RightArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1.5L17 10.5L8 19.5" stroke={color} stroke-width="1.5"/>
        <path d="M17 10.5H0" stroke={color} stroke-width="1.5"/>
      </svg>
    )
  }
}

class LeftArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 19.5L2 10.5L11 1.5" stroke={color} stroke-width="1.5"/>
        <path d="M2 10.5L19 10.5" stroke={color} stroke-width="1.5"/>
      </svg>
    )
  }
}


export { Logo, Close, DownArrow, RightArrow, LeftArrow }