import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo } from '../components/icons'

class Header extends Component {

  state = {
    offCanvas: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className={ offCanvas ? 'header active' : 'header' }>
          <div className='header__inner'>
            <div className='header__left'>
              <nav className='header__nav'>
                <ul>
                  <li><Link to='/about' {...props}>About</Link></li>
                  <li><Link to='/ethos' {...props}>Ethos</Link></li>
                </ul>
              </nav>
            </div>

            <div className='header__center'>
              <Link to='/' title='Elder London' className='header__logo' {...props}>
                <Logo color={'#000'} />
              </Link>
            </div>

            <div className='header__right'>
              <nav className='header__nav'>
                <ul>
                  <li><Link to='/news' {...props}>News</Link></li>
                  <li><Link to='/project/w10/' {...props}>Projects</Link></li>
                </ul>
              </nav>
            </div>

            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>

          </div>
        </header>

        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
              <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/about' {...props}>About</Link></li>
                <li><Link to='/ethos' {...props}>Ethos</Link></li>
                <li><Link to='/news' {...props}>News</Link></li>
                <li><Link to='/project/w10/' {...props}>Projects</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
